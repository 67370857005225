.reset-section-counter {
  counter-reset: section-counter 0;
}
.increment-section-counter {
  counter-increment: section-counter;
}

.section-counter-text:before {
  counter-increment: section-counter; /* On incrémente le compteur section */
  content: counter(section-counter); /* On affiche le compteur */
}

.section-counter-inside {
  counter-increment: section-counter; /* On incrémente le compteur section */
  content: counter(section-counter); /* On affiche le compteur */
}

.section-counter:before {
  border-radius: 50%;
  font-size: 1.25rem;
  background-color: var(--bs-primary);
  display: inline-block;
  padding: 0.35em 0.65em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-right: 1rem;
  counter-increment: section-counter; /* On incrémente le compteur section */
  content: counter(section-counter); /* On affiche le compteur */
}
