$primary: #feba01;
$secondary: #ff96bb;
$tertiary: #30c9eb;
$success: #00c462;
$dark: #314559;
$medium: #5a6a7a;
$light: #c1c7cd;
$light-30p: #f8f8f7;
$warning: #ef703e;
$danger: #d82929;
$info: #30c9eb;
$gris-hiji: #f9f9f9;

//nouvelle couleur secondaire hiji bleu azure très light au 11/12/2024 #eefcff
