@import 'colors_vars';

ic2-switch {
  > span {
    background-color: $dark;

    &.checked {
      background-color: $success;
      border-color: $success !important;
    }
  }
}
