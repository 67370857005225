@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-Black.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-BlackItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-Bold.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-BoldItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-ExtraBold.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-ExtraLight.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-ExtraLightItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-Italic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-Light.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-LightItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-Medium.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-MediumItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-Thin.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('/assets/fonts/montserrat/static/Montserrat-ThinItalic.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-VariableFont_wght.ttf');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('/assets/fonts/montserrat/Montserrat-Italic-VariableFont_wght.ttf');
}
