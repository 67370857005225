@use 'sass:color';
@import 'colors_vars';

.table-center {
  td,
  th {
    text-align: center;
  }
}

.table {
  th,
  td {
    vertical-align: middle;
  }
}

ic2-my-table tbody tr.hoverable:hover {
  background-color: color.adjust($primary, $lightness: 30%) !important;
}
