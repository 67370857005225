@use 'sass:color';

$border-radius: 0.25rem;
$border-radius-lg: 1rem;
$border-radius-sm: 2px;

$body-color: #314559;
$form-check-label-disabled-opacity: 1;

@import '_colors_vars.scss';

$global: $dark;
$auto-observation: $tertiary;
$manager: $primary;
$pair: $warning;
$collaborateur: $secondary;
$externe: $medium;
$moyenne: $danger;
$coach: $warning;

$enseigne: $secondary;
$societe: $warning;
$region: $medium;
$etablissement: $tertiary;
$cible: $success;

$acquisition-before: $auto-observation;
$acquisition-after: #0070c0;

$actions-j10: $success;
$actions-j42: color.adjust($actions-j10, $lightness: -10%);
$actions-manager: $manager;

$rank-1: #48bbc2;
$rank-2: #56af94;
$rank-3: #2a4897;

$hiji-colors: (
  global: $global,
  auto-observation: $auto-observation,
  manager: $manager,
  equipe: #2d6975,
  pair: $pair,
  collaborateur: $collaborateur,
  externe: $externe,
  moyenne: $moyenne,
  coach: $coach,
  enseigne: $enseigne,
  societe: $societe,
  region: $region,
  etablissement: $etablissement,
  cible: $cible,
  acquisition-before: $acquisition-before,
  acquisition-after: $acquisition-after,
  actions-j10: $actions-j10,
  actions-j42: $actions-j42,
  actions-manager: $actions-manager,
  rank-1: $rank-1,
  rank-2: $rank-2,
  rank-3: $rank-3,
  mif-quiz: $auto-observation,
);

$theme-colors: (
  primary: $primary,
  success: $success,
  secondary: $secondary,
  tertiary: $tertiary,
  dark: $dark,
  medium: $medium,
  light: $light,
  light-30p: $light-30p,
  warning: $warning,
  danger: $danger,
  info: $info,
  gris-hiji: $gris-hiji,
);

@if variable-exists(client-theme-colors-override) {
  $theme-colors: map-merge($theme-colors, $client-theme-colors-override);
}

@if variable-exists(hiji-theme-colors-override) {
  $hiji-colors: map-merge($hiji-colors, $hiji-theme-colors-override);
}

@if not variable-exists(link-color) {
  $link-color: $primary;
}

/*
/* we should do it like that but it seem not to work (removes bg-white)
$custom-theme-colors: map-merge(
  $theme-colors,
  (
    primary: $primary,
    success: $success,
    secondary: $secondary,
    dark: $dark,
    medium: $medium,
    light: $light,
    light-30p: $light-30p,
    warning: $warning,
    danger: $danger,
    info: $info,
  )
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'text');
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, '$key', 'bg');
*/