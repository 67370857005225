form.ng-submitted {
  input:not([type='checkbox']):not([type='submit']):not([type='button']) {
    &.ng-invalid:not(:focus) {
      border: 1px solid $danger !important;
      //background-color: lighten($light, 20%) !important;
      color: $danger !important;
    }
  }
}

select {
  height: 2.5rem;
}

input.form-control:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='button']) {
  height: 2.5rem !important;
  &:not(.dashed-when-disabled) {
    color: $dark !important;
  }
  //background-color: #fff !important;

  &:focus {
    //background-color: #fff !important;
    border: 1px solid $info !important;
    box-shadow: unset !important;
    color: $dark !important;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #fff inset;
  }
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}
.input-group > .input-group-text > .btn,
.input-group > .btn {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}
.input-group:focus-within {
  > .input-group-prepend {
    .input-group-text {
      border: 1px solid;
    }
  }
  > .input-group-append {
    .input-group-text {
      border: 1px solid;
    }
  }
}

//Remove steppers on input number for all the platfrom
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.errors {
  color: white;
  background-color: $danger;
  padding: 1rem 1rem 1rem 2rem;
  border-radius: $border-radius;
  //md > px-5
  @include media-breakpoint-up(lg) {
    padding: 1rem 3rem 1rem 3rem;
  }
}
